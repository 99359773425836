import * as React from 'react';
import { LayoutProvider } from '../components/layout';
import { IProductLinesLanding } from '../@types';
import { useTableOrMobile } from '../hooks';
import {
  ProductLineShowcase,
  ActionBanner,
  CallToActionBanner,
  ActionButtons,
  ParallaxBanner,
  ProductListing,
  FindMySafeAndCarousel,
} from '../components/sections';
import IPage from '../@types/IPage';

const ProductLine = (props: IPage<IProductLinesLanding>) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const data = props.pageContext;

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <ParallaxBanner
        strapiProductLinesLanding={data.strapiProductLinesLanding}
      />
      <ActionButtons
        id={data.strapiProductLinesLanding.actions.id}
        enabled={data.strapiProductLinesLanding.actions.enabled}
        links={data.strapiProductLinesLanding.actions.links}
      />
      <ProductLineShowcase
        isMobile={isMobile}
        isTablet={isTablet}
        href={data.strapiProductLinesLanding.listing.href}
        iconButton={data.strapiProductLinesLanding.listing.iconButton.url}
        action={data.strapiProductLinesLanding.listing.action}
        description={data.strapiProductLinesLanding.listing.description}
        enabled={data.strapiProductLinesLanding.listing.enabled}
        id={data.strapiProductLinesLanding.listing.id}
        subtitle={data.strapiProductLinesLanding.listing.subtitle}
        title={data.strapiProductLinesLanding.listing.title}
        listingSeries={data.strapiProductLinesLanding.listing.listingSeries}
      />
      <ActionBanner
        actions={data.strapiProductLinesLanding.actionBanner.actions}
        description={data.strapiProductLinesLanding.actionBanner.description}
        enabled={data.strapiProductLinesLanding.actionBanner.enabled}
        id={data.strapiProductLinesLanding.actionBanner.id}
        image={data.strapiProductLinesLanding.actionBanner.image}
        title={data.strapiProductLinesLanding.actionBanner.title}
      />
      <FindMySafeAndCarousel
        isMobile={isMobile}
        isTablet={isTablet}
        inputs={data.strapiProductLinesLanding?.fits?.inputs}
        enabled={data.strapiProductLinesLanding?.fits?.enabled}
        id={data.strapiProductLinesLanding.fits.id}
        action={data.strapiProductLinesLanding.fits.action}
        title={data.strapiProductLinesLanding.fits.title}
        subtitle={data.strapiProductLinesLanding.fits.subtitle}
        description={data.strapiProductLinesLanding.fits.description}
        safesCarousel={data.strapiProductLinesLanding.fits.safesCarousel}
        backgroundImage={data.strapiProductLinesLanding.fits.backgroundImage}
        frontImage={data.strapiProductLinesLanding.fits.frontImage}
        dummyImg={data.strapiProductLinesLanding.fits.dummyImg}
      />
      <ProductListing
        enabled={data.strapiProductLinesLanding.recommended.enabled}
        id={data.strapiProductLinesLanding.recommended.id}
        isMobile={isMobile}
        title={data.strapiProductLinesLanding.recommended.title}
        description={data.strapiProductLinesLanding.recommended.description}
        compareItems={data.strapiProductLinesLanding.recommended.compareItems}
      />
      <CallToActionBanner
        id={data.strapiProductLinesLanding.history.id}
        title={data.strapiProductLinesLanding.history.title}
        description={data.strapiProductLinesLanding.history.description}
        enabled={data.strapiProductLinesLanding.history.enabled}
        action={data.strapiProductLinesLanding.history.action}
        backgroundImage={data.strapiProductLinesLanding.history.backgroundImage}
        href={data.strapiProductLinesLanding.history.href}
      />
    </LayoutProvider>
  );
};

export default ProductLine;
